/**
 * @generated SignedSource<<2fa10e5292cdc80fd7ba3b59ea0e65c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UploadPhotoMutation$variables = {
  file?: any | null;
};
export type UploadPhotoMutation$data = {
  readonly uploadPhoto: {
    readonly filename: string | null;
    readonly url: string | null;
  } | null;
};
export type UploadPhotoMutation = {
  response: UploadPhotoMutation$data;
  variables: UploadPhotoMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "file"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "file",
        "variableName": "file"
      }
    ],
    "concreteType": "UploadedFile",
    "kind": "LinkedField",
    "name": "uploadPhoto",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "filename",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UploadPhotoMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UploadPhotoMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c0a196ce46e7ac7db19b66f39cbf0494",
    "id": null,
    "metadata": {},
    "name": "UploadPhotoMutation",
    "operationKind": "mutation",
    "text": "mutation UploadPhotoMutation(\n  $file: Upload\n) {\n  uploadPhoto(file: $file) {\n    url\n    filename\n  }\n}\n"
  }
};
})();

(node as any).hash = "a5022db15a53a5d767782b3342c3e376";

export default node;
