/**
 * @generated SignedSource<<e33b2d89efbfbb2f0387d73287891110>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EntityType = "ART" | "EXAM" | "GENERIC" | "LESSON" | "PERSON" | "TECH" | "THEORY" | "%future added value";
export type Status = "PRIVATE" | "PROTECTED" | "PUBLIC" | "%future added value";
export type PostQuery$variables = {
  permalink?: string | null;
};
export type PostQuery$data = {
  readonly post: {
    readonly body: string | null;
    readonly createdAt: string | null;
    readonly darkThemeImageUrl: string | null;
    readonly description: string | null;
    readonly entityEndDate: string | null;
    readonly entityStartDate: string | null;
    readonly entityType: EntityType | null;
    readonly lightThemeImageUrl: string | null;
    readonly modifiedAt: string | null;
    readonly permalink: string | null;
    readonly socialImageUrl: string | null;
    readonly status: Status | null;
    readonly title: string | null;
  } | null;
};
export type PostQuery = {
  response: PostQuery$data;
  variables: PostQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "permalink"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "permalink",
        "variableName": "permalink"
      }
    ],
    "concreteType": "Post",
    "kind": "LinkedField",
    "name": "post",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "permalink",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "modifiedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "body",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lightThemeImageUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "darkThemeImageUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "socialImageUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "entityType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "entityStartDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "entityEndDate",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PostQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PostQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "338dec1733b00e7572df4cf278029d6d",
    "id": null,
    "metadata": {},
    "name": "PostQuery",
    "operationKind": "query",
    "text": "query PostQuery(\n  $permalink: String\n) {\n  post(permalink: $permalink) {\n    permalink\n    title\n    description\n    createdAt\n    modifiedAt\n    body\n    status\n    lightThemeImageUrl\n    darkThemeImageUrl\n    socialImageUrl\n    entityType\n    entityStartDate\n    entityEndDate\n  }\n}\n"
  }
};
})();

(node as any).hash = "a46635ba707b5943ebe7452c68957cbd";

export default node;
