export function formatDate(dateText: string) {
  const date = new Date(dateText);
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
  const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
  const era = new Intl.DateTimeFormat('en', { era: 'short' }).format(date);
  return { year, month, day, era };
}

export function extractIsoYear(dateText: string) {
  if (dateText.startsWith('-')) {
    return `-${dateText.split('-')[1]}`;
  } else {
    return dateText.split('-')[0];
  }
}
