/**
 * @generated SignedSource<<832a259d871ec49046b0746afd81876a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SendEmailToSubscribersMutation$variables = {
  body?: string | null;
  subject?: string | null;
};
export type SendEmailToSubscribersMutation$data = {
  readonly sendEmailToSubscribers: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  } | null;
};
export type SendEmailToSubscribersMutation = {
  response: SendEmailToSubscribersMutation$data;
  variables: SendEmailToSubscribersMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "body"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subject"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "body",
        "variableName": "body"
      },
      {
        "kind": "Variable",
        "name": "subject",
        "variableName": "subject"
      }
    ],
    "concreteType": "ValidationResponse",
    "kind": "LinkedField",
    "name": "sendEmailToSubscribers",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SendEmailToSubscribersMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SendEmailToSubscribersMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "8b9bc28baa8059b1c456513622859798",
    "id": null,
    "metadata": {},
    "name": "SendEmailToSubscribersMutation",
    "operationKind": "mutation",
    "text": "mutation SendEmailToSubscribersMutation(\n  $subject: String\n  $body: String\n) {\n  sendEmailToSubscribers(subject: $subject, body: $body) {\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "50f9fccd23ef28cb5fc43b0213f79110";

export default node;
